<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :isOperateColumn="false"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="导出" @click="toExport"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="用户名" v-model="searchParam.userName" />
      </template>
      <!-- <template #operateSlot="scope">
        
      </template> -->
    </v-list>
  </div>
</template>

<script>
import {
  getAssignPointsRecordListUrl,
  exportAssignPointsRecordListUrl,
} from "./api.js";
import {} from "./map.js";
import { createHTMLVNode } from "@/utils/utils.js";

export default {
  name: "pointsRecordList",
  data() {
    return {
      form: {
        recordType: "",
        pointCount: "",
        tenantId: "",
      },
      searchParam: {
        userName: "",
      },
      exportParams: {
        url: getAssignPointsRecordListUrl,
        params: {},
      },

      tableUrl: getAssignPointsRecordListUrl,
      headers: [
        {
          prop: "userName",
          label: "用户名",
        },
        {
          prop: "tokenPoint",
          label: "获取积分",
        },
        {
          prop: "tenantName",
          label: "租户社区",
        },
        {
          prop: "recordTypeName",
          label: "积分类型来源",
        },
        {
          prop: "optUserName",
          label: "操作人",
        },
        {
          prop: "insertTime",
          label: "获取时间",
        },
      ],
    };
  },
  computed: {},
  methods: {
    // 导出
    toExport() {
      let params = {};
      this.$axios
        .post(
          `${exportAssignPointsRecordListUrl}`,
          {},
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          console.log(res, "res-----");
          // 得到请求到的数据后，对数据进行处理
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;charset=utf-8",
          }); // 创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
          console.log(res.headers, "res.headers");
          let fileName = decodeURI(res.headers["content-disposition"]); // 设置文件名称,decodeURI：可以对后端使用encodeURI() 函数编码过的 URI 进行解码。encodeURI() 是后端为了解决中文乱码问题
          if (fileName) {
            // 根据后端返回的数据处理文件名称
            fileName = fileName.substring(fileName.indexOf("=") + 1);
          }
          console.log(fileName, "fileName");
          const link = document.createElement("a"); // 创建一个a标签
          link.download = fileName; // 设置a标签的下载属性
          link.style.display = "none"; // 将a标签设置为隐藏
          link.href = URL.createObjectURL(blob); // 把之前处理好的地址赋给a标签的href
          document.body.appendChild(link); // 将a标签添加到body中
          link.click(); // 执行a标签的点击方法
          URL.revokeObjectURL(link.href); // 下载完成释放URL 对象
          document.body.removeChild(link); // 移除a标签
        });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
